import React from 'react';
import './GettingMarriedSection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const GettingMarriedSection = ({
    id
}) => {

    return (
        <div id={id} className="getting-married-section section-padding clear section-padding bg-pink">
            <Container>
                <Row>
                    <Col md="12" className='text-center'>
                        <div className='logo'>
                            <img src='/icons/logo.png' />
                        </div>
                        <h3 className="title">Are getting married!</h3>
                        <h4 className="subtitle">March 22, 2025 — Ajijic, Jalisco, México</h4>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default GettingMarriedSection;