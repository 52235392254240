import React, { useState } from 'react';
import './RspvSection.scss';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';

const RspvSection = ({
    id
}) => {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [guests, setGuests] = useState('');
    const [coming, setComing] = useState(true);
    const [comingPreParty, setComingPreParty] = useState(true);
    const [email, setEmail] = useState('');
    const [isVegan, setIsVegan] = useState(false);
    const [isAlergic, setIsAlergic] = useState(false);
    const [alergies, setAlergies] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSe5FxrZUX2ARWL8ybbR64jr6VhoBuTE0Sia1-_fHz73mKA6dA/formResponse';

        const formBody = new URLSearchParams();
        formBody.append('entry.2136227755', name);
        formBody.append('entry.1805221796', lastName);
        formBody.append('entry.1169715525', guests);
        formBody.append('entry.195504068', coming ? 'YES' : 'NO');
        formBody.append('entry.1832880436', coming && comingPreParty ? 'YES' : 'NO');
        formBody.append('entry.654942184', email);
        formBody.append('entry.717672225', isVegan ? 'YES' : 'NO');
        formBody.append('entry.1473349564', isAlergic ? alergies : 'NO');
        formBody.append('entry.956935380', message);


        fetch(googleFormUrl, {
            method: 'POST',
            body: formBody.toString(),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            mode: 'no-cors'
        }).then((result) => {
            console.log('TODO BIEN:');
            console.log(result);
            setLoading(false);
            setSent(true);
        }).catch((error) => {
            console.log('UPS, error');
            console.log(error);
            setLoading(false);
            setSent(true);
            setError(true);
        });
    };

    return (
        <div id={id} className="rspv-section section-padding bg-img bg-fixed">

            <Container>

                <Row className="justify-content-md-center" onSubmit={handleSubmit}>
                    <Col md="6" className='bg-white p-40'>
                        <span className="title-meta text-center">Will you attend?</span>
                        <h2 className="title text-center">R.S.V.P</h2>

                        {sent && !error && <Row className="justify-content-md-center">
                            <Col className='mt-5 mb-5 text-center confirmation'>
                                <div className='check'>
                                    <i className='ti-check'></i>
                                </div>
                                Thank you, we hope to see you soon!
                            </Col>
                        </Row>}
                        {sent && error && <Row className="justify-content-md-center">
                            <Col className='mt-5 mb-5 text-center confirmation'>
                                <div className='error'>
                                    <i className='ti-face-sad'></i>
                                </div>
                                There has been an error, write to us to confirm!
                            </Col>
                        </Row>}
                        {!sent && <Form className='mt-4'>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Guest(s)</Form.Label>
                                <Form.Control
                                    as='textarea'
                                    value={guests}
                                    rows={5}
                                    onChange={(e) => setGuests(e.target.value)} />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Will you be able to join us?</Form.Label>
                                <Form.Check
                                    className='mb-2'
                                    type='radio'
                                    label='Absolutely'
                                    name='comming'
                                    checked={coming}
                                    onClick={() => {
                                        setComing(true)
                                    }} />
                                <Form.Check
                                    type='radio'
                                    label={<span>I can't make it <i className='ti-face-sad' /></span>}
                                    name='comming'
                                    checked={!coming}
                                    onClick={() => {
                                        setComing(false)
                                    }} />
                            </Form.Group>

                            {coming && <Form.Group className="mb-3">
                                <Form.Label>Will you attend the welcome party the day before?</Form.Label>
                                <Form.Check
                                    className='mb-2'
                                    type='radio'
                                    label="Yes, I'm going to everything"
                                    name='comingPreParty'
                                    checked={comingPreParty}
                                    onClick={() => {
                                        setComingPreParty(true)
                                    }}
                                />
                                <Form.Check
                                    type='radio'
                                    label='No, see you on Saturday'
                                    name='comingPreParty'
                                    checked={!comingPreParty}
                                    onClick={() => {
                                        setComingPreParty(false)
                                    }} />
                            </Form.Group>}

                            {coming && <>
                                <Form.Label>Dietary restrictions</Form.Label>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        id="vegetarian"
                                        type="checkbox"
                                        label="Vegetarian"
                                        value={isVegan}
                                        onChange={(e) => {
                                            setIsVegan(e.target.checked)
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        id="alergias"
                                        type="checkbox"
                                        label="Allergies"
                                        value={isAlergic}
                                        onChange={(e) => {
                                            setIsAlergic(e.target.checked)
                                        }}
                                    />
                                    {isAlergic && <Form.Control
                                        type='text'
                                        placeholder='Let us know your allergies.'
                                        value={alergies}
                                        onChange={(e) => {
                                            setAlergies(e.target.value)
                                        }} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Tell us your thoughts: What song must be played at the party?</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        value={message}
                                        rows={5}
                                        onChange={(e) => setMessage(e.target.value)} />
                                </Form.Group>
                            </>}

                            <Button disabled={loading} type="submit">Send</Button>
                        </Form>}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RspvSection;