import React from 'react';
import './StorySection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const StorySection = ({
    id
}) => {
    return (
        <div id={id} className="story-section section-padding">
            <Container>
                <Row>
                    <Col md="5" className='mb-30'>
                        <div className='story-img'>
                            <div className='img'>
                                <img className='img-fluid' src='images/story/story-cover.webp' alt='' />
                            </div>
                        </div>
                    </Col>
                    <Col md="7">
                        <h3 className='story-title'>Our Story</h3>

                        <p>
                            Hello everyone, we are Esme and Carrillo. We want to share with you how our story began. In 2022, we were in Vancouver, Canada, during the ski season, and by chance, we ended up in the same bar thanks to some mutual friends. We started talking and, surprise, we realized that we share a great passion for skiing! We decided to go skiing together, and from then on, we haven't stopped.
                        </p>
                        <p>
                            <b>Fun to the Fullest!</b> Over time, we discovered that not only do we share a love for skiing, but also for traveling, eating, and embarking on new adventures. Every trip and experience have been moments filled with laughter and unforgettable memories.
                        </p>
                        <p>
                            And here comes the best part: in the summer of 2023, we returned to the same mountain where we first skied together. There, surrounded by nature and memories, we committed to being adventure partners forever. Since that day, our lives have been full of joy, and we are grateful for all the love and support we have received from all of you.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StorySection;